import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Ajouter bon de commande existant")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{staticClass:"white--text",attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Ajouter le bon de commande")])],1),_c(VCardText,[_c(VFileInput,{attrs:{"label":"Bon de commande"},model:{value:(_vm.bonFile),callback:function ($$v) {_vm.bonFile=$$v},expression:"bonFile"}})],1),_c(VCardActions,[(_vm.creating)?_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":""}})],1):_vm._e(),_c(VBtn,{attrs:{"disabled":_vm.creating,"color":"primary"},on:{"click":_vm.addBonFile}},[_vm._v(" Modifier ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }