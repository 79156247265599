<template>
  <v-container>
    <v-toolbar>
      <v-toolbar-title class="primary--text">Travaux du dossier <span v-if="selected_dossier !== null">#{{selected_dossier.id}} {{selected_dossier.beneficiaires[0].contact.nom }} {{selected_dossier.beneficiaires[0].contact.prenom }}</span></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="devis" color="primary" :to="`/dossier/${devis.dossier}`" text>
        <v-icon>mdi-chevron-left</v-icon>
        Retour au dossier
      </v-btn>
    </v-toolbar>
    <div v-if="noDevisError">
      <v-row class="mt-4">
        <v-col class="error-banner" cols="10" offset="1">
          <span class="red--text">Une erreur est survenue.</span><br>
          <router-link to="/dossier">Retour à la liste des dossiers</router-link>
        </v-col>
      </v-row>
    </div>
    <!-- Informations Devis --->
    <v-row justify="center" align="center" no-gutters class="pa-4" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <!-- Listing Devis --->
    <div v-else-if="devis != null">
      <div v-if="devis.type !== 'import'">
        <v-row justify="center" align="center" class="my-2">
          <h2 class="primary--text">Opérations de devis à assigner</h2>
        </v-row>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>Catégorie</th>
              <th>Code</th>
              <th>Description</th>
              <th>Unité</th>
              <th>Montant</th>
              <th>Quantité</th>
              <th>Sous-total</th>
              <th>Assigné</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="operation in devis.operations" :key="operation.id">
              <td v-if="operation.category !== null">{{ operation.category.name }}</td>
              <td v-else>Sans catégorie</td>
              <td>{{ operation.code }}</td>
              <td>{{ operation.description }}</td>
              <td>{{ operation.unit }}</td>
              <td>{{ operation.price }} €</td>
              <td>{{ operation.quantity }}</td>
              <td>{{ operation.subtotal }}</td>
              <td>{{ usedOperationsIds.indexOf(operation.id) !== -1 ? "✅" : "❌" }}</td>
              <td>
              </td>
            </tr>
            <tr>
              <td colspan="3"><strong>Total</strong></td>
              <td><strong>TVA : 2.10%</strong></td>
              <td>{{ total_operations }} €</td>
              <td></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="text-center" v-else>
        <div class="my-4 font-italic">Devis importé</div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>Montant total du devis</th>
              <th>Total des commandes</th>
              <th>Reste à définir</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ devis.total_ttc }} €</td>
              <td>{{ total_commandes }} €</td>
              <td>{{ devis.total_ttc - total_commandes }} €</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <!-- Créer Bon de commande --->
      <v-toolbar class="mt-4">
        <v-toolbar-title class="primary--text">Commandes</v-toolbar-title>
        <v-spacer></v-spacer>
        <CommandeForm v-if="isIntern || isConducteurDeTravaux" @reload="loadDevis(devis.id)" :devis="devis" :operations="unused_operations"
                      :dossier="selected_dossier" operation="create"/>

      </v-toolbar>
      <v-row>
        <v-col class="my-4" v-for="commande in commandes" :key="commande.id" cols="12">
          <v-card class="pa-4">
            <v-row no-gutters>
              <v-card-title class="primary--text">Commande à l'entreprise {{ commande.entreprise.name }}</v-card-title>
              <v-spacer></v-spacer>
              <CommandeForm v-if="isIntern || isConducteurDeTravaux" @reload="loadDevis(devis.id)" :devis="devis" :commande="commande"
                            :dossier="selected_dossier" operation="update"/>
              <DeleteCommandeForm v-if="isIntern || isConducteurDeTravaux" @reload="loadDevis(devis.id)" :commande="commande"/>
            </v-row>
            <div v-for="metier in commande.entreprise.metiers" :key="metier.id">
              <v-chip color="primary">{{ metier.name }}</v-chip>
            </div>
            <br>
            <div class="body-1">Durée estimée des travaux</div>
            <div class="body-2">{{ commande.duree_travaux || "Non défini" }}</div>
            <br>
            <div class="body-1">Date de début des travaux</div>
            <div class="body-2">{{ getReadableDate(commande.date_travaux) || "Non défini" }}</div>
            <br>
            <div v-if="devis.type === 'import'">
              <div class="body-1">Montant total TTC</div>
              <div class="body-2">{{ commande.total }} €</div>
              <br>
            </div>
            <v-card-text>
              <div v-if="devis.type ==='normal'">
                <h2 class="primary--text">Opérations affectés</h2>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Catégorie</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Unité</th>
                      <th>Montant</th>
                      <th>Quantité</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="assignment in commande.assignments" :key="assignment.id">
                      <td v-if="assignment.operation.category !== null">{{ assignment.operation.category.name }}</td>
                      <td v-else>Sans catégorie</td>
                      <td>{{ assignment.operation.code }}</td>
                      <td>{{ assignment.operation.description }}</td>
                      <td>{{ assignment.operation.unit }}</td>
                      <td>{{ assignment.operation.price }} €</td>
                      <td>{{ assignment.operation.quantity }}</td>
                      <td>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7">
                        <AddOperationToCommande v-if="isIntern || isConducteurDeTravaux" @reload="loadCommandes(devis.id)" :commande="commande"
                                                :unused_operations="unused_operations"/>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"><strong>Total</strong></td>
                      <td><strong>TVA : 2.10%</strong></td>
                      <td>{{ commande.total.toFixed(2) }} €</td>
                      <td></td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <v-row  v-if="isIntern || isConducteurDeTravaux" no-gutters align="center" justify="space-between"><p>
                <span class="font-weight-bold primary--text">Bon de commande</span><br>
                <span v-if="commande.bon === null" class="font-italic">Non généré</span>
                <span v-else><a :href="commande.bon" target="_blank">Accéder au document</a></span>
              </p>
                <AddBonCommande :commande="commande" @reload="loadCommandes(devis.id)"/>
                <v-btn v-if="devis.type === 'normal'" @click="generateBon(commande.id)" color="primary">Générer le bon de commande</v-btn>
              </v-row>
              <v-divider></v-divider>
              <v-row v-if="isIntern">
                <v-col cols="12">
                  <h2 class="primary--text my-4">
                    Infos financières
                  </h2>
                  <v-chip v-if="commande.is_paid" color="primary">Payé</v-chip>
                  <v-chip v-else color="secondary">En attente de paiement</v-chip>
                  <PayCommandeForm @reload-commandes="loadCommandes(devis.id)" v-if="!commande.is_paid" :devis="devis"
                                   :commande="commande"/>

                </v-col>
              </v-row>
              <h3 v-if="isIntern" class="primary--text my-4">
                Règlements
              </h3>
              <v-simple-table v-if="isIntern" dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Date de la facture
                    </th>
                    <th class="text-left">
                      Date du règlement
                    </th>
                    <th class="text-left">
                      Montant
                    </th>
                    <th class="text-left">
                      Statut
                    </th>
                    <th>Fichier</th>
                    <th>
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="reglement in commande.reglements"
                      :key="reglement.id"
                  >
                    <td>{{ getFormattedDate(reglement.date_facture) }}</td>
                    <td>{{ getFormattedDate(reglement.date_reglement) }}</td>
                    <td>{{ reglement.montant }} €</td>
                    <td>
                      <ReglementStatusChip :reglement="reglement"/>
                    </td>
                    <td>
                      <v-btn target="_blank" :href="reglement.file" icon color="primary" v-if="reglement.file">
                        <v-icon>mdi-paperclip</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn @click="deleteReglement(reglement)" v-if="reglement.status === 'waiting'" icon color="red">
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                      <add-file-reglement @reload="loadCommandes(devis.id)" saisie_type="decaissement" :reglement="reglement"></add-file-reglement>
                    </td>
                  </tr>
                  <tr v-if="commande.reglements.length > 0">
                    <td class="font-weight-bold h4">
                      Total
                    </td>
                    <td></td>
                    <td>
                      {{getTotal(commande).toFixed(2)}} €
                    </td>
                    <td colspan="3"></td>
                  </tr>
                  <tr v-if="commande.reglements.length > 0">
                    <td class="font-weight-bold h4">
                      Total payé
                    </td>
                    <td></td>
                    <td>
                      {{getTotalPaid(commande).toFixed(2)}} €
                    </td>
                    <td colspan="3"></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </div>

    <!-- Listing Devis --->
    <!-- Listing Documents --->

  </v-container>
</template>

<script>
import {DOSSIER_FETCH_STATUS} from "@/constants/DossierStatus";
import {EntrepriseRepository} from "@/repository/entreprises";
import {CommandeRepository} from "@/repository/commandes";
import {ReglementRepository} from "@/repository/reglement";
import CommandeForm from "@/views/CommandeForm.vue";
import AddOperationToCommande from "@/views/AddOperationToCommande.vue";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import PayCommandeForm from "@/views/PayCommandeForm.vue";
import ReglementStatusChip from "@/components/finances/ReglementStatusChip.vue";
import DeleteCommandeForm from "@/views/DeleteCommandeForm.vue";
import AddBonCommande from "@/views/AddBonCommande.vue";
import AddFileReglement from "@/views/AddFileReglement.vue";
import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: "TravauxDetails",
  components: {
    AddFileReglement,
    AddBonCommande,
    DeleteCommandeForm, ReglementStatusChip, PayCommandeForm, AddOperationToCommande, CommandeForm
  },
  mixins: [DateUtilMixin, AuthMixin],
  async mounted() {
    let that = this;
    /** @type IApi<Entreprise> */
    let entrepriseRepo = new EntrepriseRepository();
    entrepriseRepo.fetch().then((data) => that.entreprises = data);
    if (this.fetch_status !== DOSSIER_FETCH_STATUS.loaded) {
      let dossierId = this.$route.params?.id;
      await this.$store.dispatch("dossier/fetchDossierById", dossierId);
    }
    let devis = this.selected_dossier.devis.reduce((a, b) => a.date_creation > b.date_creation ? a : b, {
      id: 0,
      date_creation: "1970-01-01"
    });
    if (devis.id === 0) {
      this.noDevisError = true;
    } else {
      await this.loadDevis(devis.id);
    }
  },
  data() {
    return {
      loading: false,
      noDevisError: false,
      devis: null,
      /** @type Commande[] */
      commandes: [],
      /** @type Entreprise[] */
      entreprises: [],
    }
  },
  methods: {
    async loadDevis(devisId) {
      let commandeRepo = new CommandeRepository();
      this.loading = true;
      this.devis = await this.$store.dispatch("devis/getDevisById", {id: devisId});
      this.commandes = await commandeRepo.fetchByDevisId(devisId);
      this.montantTotal = this.devis.total;
      this.loading = false;
    },
    async loadCommandes(devisId) {
      let commandeRepo = new CommandeRepository();
      let result = await commandeRepo.fetchByDevisId(devisId);
      this.commandes = [];
      let that = this;
      this.$nextTick(function () {
        that.commandes = result;
      })

    },
    async generateBon(commandeId) {
      await this.$store.dispatch("devis/generateBon", {id: commandeId});
      this.loadCommandes(this.devis.id);
    },
    async deleteReglement(reglement) {
      let repository = new ReglementRepository();
      let result = await repository.delete(reglement);
      if (result === "success") {
        await this.$store.dispatch("annonce/annonceSuccess", "Règlement supprimé");
        await this.loadCommandes(this.devis.id);
      }
    },
    async updateReglement(reglement, status) {
      let repository = new ReglementRepository();
      let result = await repository.update({id: reglement.id, status});
      if (result) {
        await this.$store.dispatch("annonce/annonceSuccess", "Règlement mis à jour");
        await this.loadCommandes(this.devis.id);
      }
    },
    getTotal(commande) {
      let total = 0;
      for(let reglement of commande.reglements) {
        total += parseFloat(reglement.montant);
      }
      return total;
    },
    getTotalPaid(commande) {
      let total = 0;
      for(let reglement of commande.reglements) {
        if (reglement.status === "paid") {
          total += parseFloat(reglement.montant);
        }
      }
      return total;
    }
  },
  computed: {
    selected_dossier() {
      return this.$store.getters["dossier/getSelectedDossier"];
    },
    allOperations() {
      return [...this.devis.operations]
    },
    used_operations() {
      let operationsList = [];
      for (var commande of this.commandes) {
        for (var assignment of commande.assignments) {
          operationsList.push(assignment.operation);
        }
      }
      return operationsList;
    },
    usedOperationsIds() {
      return this.used_operations.map((operation) => operation.id);
    },
    unused_operations() {
      let operationIds = this.usedOperationsIds;
      return this.devis?.operations.filter((operation) => operationIds.indexOf(operation.id) === -1);
    },
    fetch_status() {
      return this.$store.getters["dossier/getSingleItemFetchStatus"];
    },
    total_operations() {
      let total = this.devis.operations.map((operation) => operation.quantity * operation.price).reduce((a, b) => a + b, 0);
      return Math.round(total * 1.021 * 100) / 100;
    },
    total_commandes() {
      return this.commandes.reduce((a, b) => a + b.total, 0);
    },
    assigned_operations() {
      let assigned = []
      for (let i = 0; i < this.commandes.length; i++) {
        for (let j = 0; j < this.commandes[i].assignments.length; i++) {
          assigned.push(this.commandes[i].assignments[j].operation.id);
        }
      }
      return assigned;
    }
  }
}
</script>

